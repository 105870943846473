<template>
  <div class="" style="width: 100%;height: 100%;">
    <el-row style="width:100%;margin-bottom: 10px;" class="flexStart flex_1 ">
      <el-input size="small" v-model="searchForm.mohuTitle" clearable style="width: 300px!important;"
        placeholder="模糊搜索">
        <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
      </el-input>

      <el-date-picker size="small" style="width: 300px;margin-left: 20px;" v-model="searchForm.dateRange"
        value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>

      <el-select size="small" v-model="searchForm.sp_status" placeholder="状态"
        style="width: 150px!important;margin-left: 10px;">
        <el-option label="待入库" value="0" style="color: #1289ff;"></el-option>
        <el-option label="已入库" value="1" style="color: #67c23a;"></el-option>
        <el-option label="驳回" value="2" style="color: #f56c6c;"></el-option>
      </el-select>
      <el-button size="small" icon="el-icon-search" @click="onSearch" style="margin-left: 10px;" type="primary"
        plain>查询</el-button>
      <el-button size="small" icon="el-icon-search" @click="onReset" style="margin-left: 10px;" type="warning"
        plain>重置</el-button>

    </el-row>
    <el-row style="width:100%;margin-bottom: 10px;" class="flexBetween flex_1 ">
      <div class="flex_1 flexStart">
        <el-button size="small" type="primary" @click="addCGD">申购单入库 </el-button>
        <!-- <el-button size="small" type="primary" icon="el-icon-plus" @click="add" style="margin-left: 10px;">新增入库</el-button> -->
        <!--        <el-button  size="small" v-if="$store.state.teacher.sys_roles[0].role_code.indexOf('admin')>=0" style="margin-left: 10px;" type="warning" icon="el-icon-delete" @click="cancelRuku">
          取消入库
        </el-button>-->

        <el-button size="small" icon="el-icon-download" type="primary" plain style="margin-left: 10px;"
          @click="exportExcel">导出
        </el-button>
        <el-popover ref="mypopover" placement="right-start" width="240" trigger="click" title="导出xlsx表格列配置">
          <div class="" style="width:100%;padding: 10px 0;border-top: 1px solid #efefef;">
            <div class="flexBetween" v-for="(item, index) in exportFields" style="margin-top: 20px;">
              <span>{{ item.name }}</span>
              <el-switch :disabled="item.disabled" v-model="item.value" active-color="#2469f3">
              </el-switch>
            </div>
          </div>

        </el-popover>

        <el-button size="small" v-popover:mypopover type="primary" plain icon="el-icon-setting"
          style="margin: 0;"></el-button>
        <!--        <el-button size="small" type="primary" plain @click="importHcPrice">导入耗材单价</el-button>-->
        <el-button v-if="rkBatchBtnVisible" size="small" type="primary" @click="rkbatch">一键入库</el-button>
      </div>
    </el-row>

    <el-table :data="DataList" height="calc( 100% - 100px)" @selection-change="handleSelectionChange"
      style="width: 100%;" stripe border>
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>

      <el-table-column prop="sp_status_Name" label="状态" width="100" :show-overflow-tooltip="true" align="center">
        <template slot-scope="scope">
          <el-tag size="mini" type="primary" v-if="scope.row.sp_status == 0">待入库</el-tag>
          <el-tag size="mini" type="success" v-if="scope.row.sp_status == 1">已入库</el-tag>
          <el-tag size="mini" type="danger" v-if="scope.row.sp_status == 2">驳回</el-tag>
          <el-tag size="mini" type="info" v-if="scope.row.sp_status == 3">已取消入库</el-tag>
        </template>
      </el-table-column>

      <!-- <el-table-column prop="rk_num" label="入库单号" width="150" :show-overflow-tooltip="true" align="center"/>
      <el-table-column prop="sg_num" label="申购单号" width="150" :show-overflow-tooltip="true" align="center"/> -->
      <el-table-column prop="username" label="入库申请人" width="120" :show-overflow-tooltip="true" align="center" />
      <el-table-column prop="cdate" label="申请时间" min-width="195" align="center" />
      <el-table-column prop="storename" label="入库仓库" width="150" :show-overflow-tooltip="true" align="center" />
      <el-table-column prop="store_admin_name" label="仓库管理员" width="120" :show-overflow-tooltip="true" align="center" />
      <el-table-column prop="rk_date" label="入库时间" min-width="195" align="center" />
      <el-table-column prop="title" label="入库条数" width="85" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div @click="view(scope.row)" style="color: #409EFF;cursor: pointer;">
            {{ scope.row.hcListLen }}条>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="howmuch_rk" label="总价" width="120"></el-table-column>
      <el-table-column prop="rk_remark" label="备注" width="200">
        <template slot-scope="scope" style="width: calc( 100% - 20px );">
          <el-tooltip :enterable="false"
            :disabled="!scope.row.rk_remark || (scope.row.rk_remark && scope.row.rk_remark.length < 28)" class="item"
            effect="dark" placement="top-start">
            <div slot="content" style="width: 400px;">{{ scope.row.rk_remark }}</div>
            <div style="width: 100%;" class="overMoreLine">
              {{ scope.row.rk_remark }}
            </div>
          </el-tooltip>

        </template>
      </el-table-column>
      <el-table-column label="操作" width="250" fixed="right">
        <template slot-scope="scope">

          <el-tag size="mini" style="cursor: pointer" @click="view(scope.row)">查看</el-tag>
          <el-tag v-if="scope.row.sg_num" @click="match(scope.row)" size="mini"
            :style="scope.row.match == 0 ? 'cursor: pointer;color: #F56C6C;' : 'cursor: pointer;color: #409eff;'">
            {{ scope.row.match == 0 ? '不匹配' : '已匹配' }}
          </el-tag>
          <el-tag size="mini" v-if="(scope.row.sp_status != 1) && (userinfo.id == scope.row.create_uid)" type="danger"
            style="cursor: pointer;" @click="removeItem(scope.row)">删除
          </el-tag>
          <el-tag type="warning" size="mini" v-if="(scope.row.sp_status == 0) && (userinfo.id == scope.row.create_uid)"
            style="cursor: pointer;" @click="editItem(scope.row)">修改
          </el-tag>
          <el-tag v-if="(scope.row.sp_status == 0) && (userinfo.id == scope.row.store_admin)" type="success" size="mini"
            style="cursor: pointer;" @click="view(scope.row, 'verify')">审核
          </el-tag>
          <el-tag size="mini" type="success" style="cursor: pointer;" @click="print(scope.row)">打印</el-tag>
          <el-tag v-if="scope.row.sp_status == 0" size="mini" type="primary" style="cursor: pointer;margin-left: 5px;"
            @click="importHcPrice(scope.row)">单价导入</el-tag>

        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="cus_page" @size-change="handleSizeChange" @current-change="paginate"
      :current-page.sync="page.current_page" :page-sizes="[10, 50, 100]" :page-size="page.per_page"
      layout="total,sizes, prev, pager, next" :total="page.count">
    </el-pagination>

    <!--导出-->
    <ExportTable :exportList="exportList" :exportColumns="exportColumns"></ExportTable>


    <el-dialog title="批量导入" :visible="dialogImport" :close-on-click-modal="false"
      custom-class="cus_dialog cus_dialog_print" @close="dialogImport = false" width="30%" style="height: 300px;">
      <el-form class="" ref="addzc">
        <el-form-item>
          <el-button icon="el-icon-download" type="text">
            <a href="javascript:;" @click="downloadTemp"
              style="text-decoration: none; color: inherit;font-size: 14px;">单价导入，下载模板</a>
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-upload style="display: inline-block; margin-right: 20px" action="" :http-request="uploadTemp"
            :on-success="uploadFiles" :show-file-list="false">
            <el-button size="small" icon="el-icon-upload2" type="primary">选择文件
            </el-button>
          </el-upload>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {exportHcdjToExcel} from "@/utils/Export2Excel";

export default {
  components: {
    ExportTable: () => {
      return import('../../components/ExportTable.vue')
    },
  },
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value && value != 0) {
        return callback(new Error('请填写入库数量!'));
      }
      if (!Number.isInteger(value)) {
        callback(new Error('请输入数字值'));
      } else {
        if (value <= 0) {
          callback(new Error('不能小于0'));
        } else {
          callback();
        }
      }
    };

    return {
      exportFields: [
        { name: '入库单号', field: 'rk_num', value: true, disabled: true, },
        { name: '申购单号', field: 'sg_num', value: true, disabled: true, },
        { name: '状态', field: 'sp_status_Name', value: true, disabled: false, },
        { name: '入库申请人', field: 'username', value: true, disabled: true, },
        { name: '申请时间', field: 'cdate', value: true, disabled: false, },
        { name: '入库仓库', field: 'storename', value: true, disabled: false, },
        { name: '经办人', field: 'store_admin_name', value: true, disabled: false, },
        { name: '入库时间', field: 'rk_date', value: true, disabled: false, },
        { name: '入库总价(元)', field: 'howmuch_rk', value: true, disabled: false, },
        { name: '备注', field: 'rk_remark', value: true, disabled: false, },
      ],
      exportColumns: [],
      exportList: [],
      searchForm: {},
      DataList: [],
      multipleSelection: [],
      queryForm: {},
      page: {
        current_page: 1,
        per_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },
      dialogTitle: '新增耗材',
      dialogVisible: false,
      EditItem: {},
      disabled: false,
      optionProps: { value: 'id', label: 'storename', emitPath: false, checkStrictly: true },
      optionProps1: { value: 'id', label: 'cname', emitPath: false },
      files: [],
      logList: [],
      dialogVisible_borrow: false,
      userinfo: {},
      showUserDialog: false,
      userDialogType: null,
      dialogVisible_return: false,
      dialogVisible_distribute: false,
      dialogVisible_shenling: false,
      dialogVisible_withdraw: false,
      rkBatchBtnVisible: false,//一键入库按钮
      drawer: false,//高级搜索面板
      FieldItem: {},
      showCusField: false,
      curDate: '',
      printObj: {
        id: "#printDiv",
        popTitle: "",
        extraCss: "",
        extraHead: '<meta http-equiv="Content-Language" content="zh-cn"/>'
      },
      showPrinter: false,
      showBiaoqian: false,
      bqZcList: [],
      dialogErrorVisible: false,
      ErrLog: [],
      page_hc: {
        current_page: 1,
        pre_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },
      storeNum: null,
      rules_store: {
        num: [
          { validator: checkNum, trigger: 'blur' }
        ],
      },
      dialogImport: false,

    }
  },
  mounted() {
    this.getList();
    this.getCurDate();
    this.getCreateUser()

  },
  methods: {
    getExportList() {

      return new Promise(resolve => {
        let _this = this
        let params = {}, arr = []
        params.page = 1
        params.pagesize = 10000000000
        params.type = 'sx'
        params = { ..._this.searchForm }
        params.sDate = _this.searchForm.dateRange ? `${_this.searchForm.dateRange[0]} 00:00:00` : ''
        params.eDate = _this.searchForm.dateRange ? `${_this.searchForm.dateRange[1]} 23:59:59` : ''

        this.$http.post("/api/sx_hc_rk_list", params).then(res => {
          if (res.data.data && res.data.data.length > 0) {
            res.data.data.map(a => {
              if (a.sp_status == 0) { a.sp_status_Name = '待入库' }
              if (a.sp_status == 1) { a.sp_status_Name = '已入库' }
              if (a.sp_status == 2) { a.sp_status_Name = '驳回' }
              if (a.sp_status == 3) { a.sp_status_Name = '已取消入库' }
              a.match = 1
              a.hcListLen = 0
              if (a.hc_list) {
                a.list = JSON.parse(a.hc_list)
                a.list && a.list.map(h => {
                  if (h.cgNum != h.rkNum) {
                    a.match = 0
                  }
                  if (h.cgJine != h.rkJine) {
                    a.match = 0
                  }
                })
                a.listLen = a.list.length
              }
            })
            this.exportList = res.data.data
          }
          resolve()
        })

      })
    },

    exportExcel() {
      console.log(this.exportFields)
      const loading = this.$loading({
        lock: true,
        text: '数据准备中，请稍等...',
        spinner: 'el-icon-loading',
      });
      let _this = this

      if (this.multipleSelection && this.multipleSelection.length > 0) {
        loading.close();
        this.exportList = this.multipleSelection
        _this.preSaveExcel()
      } else {
        this.getExportList().then(r => {
          loading.close();
          _this.preSaveExcel()
        })
      }
      setTimeout(() => {
        loading.close();
      }, 2000);
    },
    preSaveExcel() {
      let _this = this
      let arr = []
      this.exportFields.map(a => {
        if (a.value) {
          arr.push(a)
        }
      })
      this.exportColumns = arr
      if (!this.exportList || this.exportList.length == 0) {
        this.$message.warning('暂无数据可导出~')
      }
      setTimeout(function () {
        _this.saveExcel('入库列表导出')
      }, 500)
    },
    saveExcel(saveTitle) {
      let _this = this
      var xlsxParam = { raw: true }
      let wb = XLSX.utils.table_to_book(document.querySelector('#exportTable'), xlsxParam);
      let wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
      try {
        FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${saveTitle ? saveTitle : "导出"}${_this.curDate}.xlsx`);
      } catch (e) {
        if (typeof console !== 'undefined')
          console.log(e, wbout)
      }
      return wbout
    },

    getCreateUser() {
      this.$http.post("/api/get_teacher_info").then(res => {
        this.userinfo = res.data
        if (this.rkBatchBtnVisible) return;
        this.rkBatchBtnVisible = this.getRkBatchBtnVisible();
      })
    },
    getList() {
      let _this = this
      _this.DataList = []
      let params = {}, arr = []
      params = { ..._this.searchForm }
      params.page = _this.page.current_page ? _this.page.current_page : 1
      params.pagesize = _this.page.per_page ? _this.page.per_page : 50
      params.sDate = _this.searchForm.dateRange ? `${_this.searchForm.dateRange[0]} 00:00:00` : ''
      params.eDate = _this.searchForm.dateRange ? `${_this.searchForm.dateRange[1]} 23:59:59` : ''

      this.$http.post("/api/sx_hc_rk_list", params).then(res => {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.map(a => {
            if (a.sp_status == 0) { a.sp_status_Name = '待入库' }
            if (a.sp_status == 1) { a.sp_status_Name = '已入库' }
            if (a.sp_status == 2) { a.sp_status_Name = '驳回' }
            if (a.sp_status == 3) { a.sp_status_Name = '已取消入库' }
            a.match = 1
            a.hcListLen = 0
            if (a.hc_list) {
              a.list = JSON.parse(a.hc_list)
              a.list && a.list.map(h => {

                if (h.cgNum != h.rkNum) {
                  a.match = 0
                }
                if (h.cgJine != h.rkJine) {
                  a.match = 0
                }
              })
              a.hcListLen = a.list.length
            }
          })
          _this.DataList = res.data.data;
          if (!this.rkBatchBtnVisible) {
            this.rkBatchBtnVisible = this.getRkBatchBtnVisible();
          }
        }
        if (res.data && res.data.page) {
          this.page = res.data.page
          this.page_hc = res.data.page
        }
      })
    },
    getRkBatchBtnVisible() {
      for (let i = 0; i < this.DataList.length; i++) {
        const item = this.DataList[i];
        if (parseInt(item.store_admin) == this.userinfo.id) {
          return true
        }
      }
      return false
    },
    view(row, type) {
      this.$$parent(this, 'view', row, type).then(r => { })
    },
    print(row) {
      this.$$parent(this, 'print', row).then(r => { })
    },
    match(row) {
      this.$$parent(this, 'match', row).then(r => { })
    },
    // 新增
    addCGD() {
      this.$$parent(this, 'dialogVisible_sgList', true).then(r => { })
    },
    //一键入库
    rkbatch() {
      const arr = [];
      if (this.multipleSelection.length > 0) {
        for (let i = 0; i < this.multipleSelection.length; i++) {
          const item = this.multipleSelection[i];
          if (item.sp_status == 1) {
            this.$message.error('选择的申购单中有已入库的!!!')
            return
          }
          if (parseInt(item.store_admin) != this.userinfo.id) {
            this.$message.error('选择的申购单中有您没权限入库的!!!')
            return
          }
          arr.push({ id: item.id, sp_status: 1, sp_remark: item.sp_remark })
        }

        this.$confirm(`是否确认入库?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post("/api/get_sign_pic").then(res => {
            if (res.data && res.data.handsign) {
              arr.forEach(item => item.ck_handsign = res.data.handsign);
              console.log(arr);
              this.$http.post("/api/sx_hc_rk_check_batch", { data: arr }).then(res => {
                this.$message({
                  type: 'success',
                  message: `入库操作成功！`
                });

                this.$parent.$refs.SXRukuTable.getList()
              })
            } else {
              this.$message.error("请先在小程序设置个人签名")
              return
            }
          })
        }).catch(() => {

        });
      } else {
        this.$message.error('未选择申购单!!!')
      }
    },
    add() {
      this.$$parent(this, 'add').then(r => { })
    },
    editItem(row, flag) {
      this.$$parent(this, 'editItem', row, flag).then(r => { })
    },
    removeItem(item) {
      this.$$parent(this, 'removeItem', item).then(r => { })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(val)
    },
    cancelRuku() {
      this.$$parent(this, 'cancelRuku', this.multipleSelection).then(r => {
      })
    },
    //导入
    uploadXls() {
      document.getElementById('addXlsbtn').click()
    },
    uploadAttch(e) {
      this.$$parent(this, 'uploadAttch', e).then(r => {
      })
    },

    //增加库存
    showStore(row) {
      this.$$parent(this, 'showStore', row).then(r => {
      })
    },


    onSearch() {
      this.page.current_page = 1
      this.getList()
    },
    onReset() {
      this.searchForm = {}
      this.page.current_page = 1
      this.getList()
    },

    dep_Arr(arr) {
      let a = true
      let fun = function (b) {
        b.map(s => {
          if (s.store_num <= 0) {
            a = false
            return;
          }
        })
      }
      fun(arr)
      return a
    },

    // 处理没有children的分组
    deleteEmptyGroup(treeData) {
      return new Promise(resolve => {
        function traversal(data) {
          data.map(item => {
            for (let info in item) {
              if (item['children']) {
                if (item['children'].length > 0) {
                  traversal(item['children'])
                }
                if (item['children'].length == 0) {
                  delete item['children']
                }
              }
            }
          })
        }

        traversal(treeData)
        resolve(treeData)
      })
    },





    handleSizeChange(val) {
      this.page.current_page = 1
      this.page.per_page = val
      this.getList();
    },
    paginate(val) {
      this.page.current_page = val
      this.getList();
    },

    handleCascaderChange(e) {
      console.log(e)
    },


    showUser(type) {
      if (type) {
        this.userDialogType = type
      }
      this.showUserDialog = true
    },

    getUser(e) {
      console.log(e)
      let _this = this
      let arr = []
      if (e && e.length > 0) {
        if (this.userDialogType == 1) {
          e.map((item, idx) => {
            arr.push({
              union_id: item.union_id,
              name: item.name,
            })
            if (_this.EditItem.target_users && _this.EditItem.target_users.length > 0) {
              _this.EditItem.target_users.map(orgnl => {
                if (orgnl.union_id == item.union_id) {
                  arr.splice(idx, 1)
                }
              })
            }
          })
          _this.EditItem.target_users = _this.EditItem.target_users.concat(arr)
          _this.EditItem = { ..._this.EditItem }
        } else if (this.userDialogType == 2) {
          _this.EditItem.target_uid = e[0].union_id
          _this.EditItem.name = e[0].name
          _this.EditItem = { ..._this.EditItem }
        }

      }
      _this.showUserDialog = false
    },

    getCurDate() {
      let d = new Date();
      let year = d.getFullYear(); //获取完整的年份(4位)
      let month = d.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      let date = d.getDate(); //获取当前日(1-31)
      d.getDay(); //获取当前星期X(0-6,0代表星期天)
      d.getTime(); //获取当前时间(从1970.1.1开始的毫秒数)
      d.getHours(); //获取当前小时数(0-23)
      d.getMinutes(); //获取当前分钟数(0-59)
      d.getSeconds(); //获取当前秒数(0-59)
      d.getMilliseconds(); //获取当前毫秒数(0-999)
      d.toLocaleDateString(); //获取当前日期
      let mytime = d.toLocaleTimeString(); //获取当前时间
      d.toLocaleString(); //获取日期与时间
      this.curDate = `${year}-${month}-${date}`
    },
    uploadFiles(e) {
      this.$http
        .post('/api/sx_price_import', {
          url: e.src,
          rk_id: this.EditItem.id,
        })
        .then((res) => {
          if (res.data.errors && res.data.errors.length > 0) {
            let html = "";
            for (let msg of res.data.errors) {
              html += `<div style='font-size:12px'>${msg}</div>`;
            }
            this.$alert(html, "未导入数据", {
              dangerouslyUseHTMLString: true,
            });
          } else {
            this.$message.success("导入成功");
          }
          this.getList();
          this.dialogImport = false;
        });
    },
    importHcPrice(row) {
      this.EditItem = { ...row }
      this.dialogImport = true;
    },
    downloadTemp() {
      let data = this.EditItem.list
      let idx = 0
      let exportData = []
      for (let item of data) {
        idx++
        exportData.push([
          idx,
          item.title,
          item.pinpai || "",
          item.xinghao || "",
          item.guige || "",
          item.cgNum,
          item.jldw,
          item.jine,
          item.bgr || "",
          item.course || "",
          item.class_name || "",
          item.storename || "",
          item.externalLinks || "",
          item.remark || ""
        ])
      }
      exportData.unshift(['序号','耗材名称','品牌','型号','规格','数量/面积','单位','单价','使用人','课程名称','班级','存放地','链接','备注'])
      exportHcdjToExcel(exportData,`耗材单价导入模板-${new Date().getTime()}`,[10,30,30,30,30,20,20,20,30,40,20,40,100,50])
    },
  }
}
</script>

<style lang="less" type="text/scss">
.pagezclist {
  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
    font-size: 12px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .labelCss {
    .el-form-item__label {
      color: #909399 !important;
      font-size: 12px;
    }
  }

  .el-input,
  .el-select,
  .el-cascader {
    width: 100% !important;
  }

  .el-dropdown {
    vertical-align: top;
  }

  .el-dropdown+.el-dropdown {
    margin-left: 15px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .cus_dropdown {
    .el-button--primary {
      color: #409EFF !important;
      background-color: #Ecf5ff !important;
      border-color: #b3d8ff !important;
    }
  }

  .el-divider {
    margin-top: 0;
  }

  .el-drawer__wrapper {
    top: 70px;
  }

  .drawer_box,
  .targetUsers {
    padding: 10px;

    .el-form-item {
      margin-bottom: 0px;
    }
  }

  .updimage {
    width: 100px;
    height: 100px;
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 10px 0;
    border: 1px dotted #ccc;
    background-size: 100% auto;
    background-position: center;
    overflow: hidden;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .cus_page {
    margin-top: 10px;

    .el-pagination__sizes {
      width: 130px;
    }
  }
}

.cell>.el-tag {
  margin-left: 10px;
}

.cell>.el-tag:nth-of-type(4n) {
  margin-left: 0;
}

.cell>.el-tag:nth-of-type(1) {
  margin-left: 0;
}
</style>
